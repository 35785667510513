import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveHero from "../components/CurveHero"
import parse from "html-react-parser"

export const query = graphql`
  query {
    wpPage(slug: {eq: "companies"}) {
    id
    companiesPage {
      fieldGroupName
      companies {
        fieldGroupName
        companyBlurb
        companyImage {
          sourceUrl
        }
      }
    }
  }
  }
`
const Companies = ({data}) => {
  const blurb = data.wpPage.companiesPage.companies[0].companyBlurb
  const img1 = data.wpPage.companiesPage.companies[0].companyImage.sourceUrl;
  const img2 = data.wpPage.companiesPage.companies[1].companyImage.sourceUrl;
    return (
      <Layout>
        <CurveHero pageTitle="Company" />
        <div className="bg-orange-100 mx-auto py-8 max-w-5/6">
          <div className="container">
            <div className="bg-orange-100 company-grid" >
                <div className="img1 abims flex align-center justify-center">
                  <img src={img1} className="img1" alt={`Company`} style={{ maxWidth: "400px" }} />
                </div>
                <div className="blurb">{ parse(blurb) }</div>
                <div className="img2 abims flex align-center justify-center max-w-5/6 object-scale-down">
                  <img  className="img2" src={img2}  alt={`Company `} style={{maxWidth:"400px"}} />
                </div>
              </div>
          </div>
        </div>
      </Layout>
    )

}
export const Head = () => <Seo title="Company"/>

export default Companies
